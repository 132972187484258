<template>
    <div class="join">
      <Top active='8'/>
      <div class="joinBox">
        <!-- <div class="nav">
          <img class="navImg" src="../assets/img/zhi2.png" alt="">
          <div class="navList">
            <div class="navItem" :class="{active:active==1}" @click="active=1">招聘入口</div>
            <div class="navItem" :class="{active:active==2}" @click="toUS">联系我们</div>
          </div>
        </div> -->
        <div class="content" v-if="active==1">
          <div class="title">
            <div class="title1">公告公示</div>
            <div class="title2">
              <!-- <span class="textFamily">Public</span> -->
              <span class="textFamily">Proclamation</span>
            </div>
          </div>
          <div class="enterBox">
            <div class="enterTitle">
              <span v-for="(item,index) in data" :key="index" @click="toItem(item.id)" :class="{active2:active2==item.id}">{{item.title}}</span>
            </div>
            <div class="enterContent">
              <!-- <p class="enterTxt1">{{job_title}}</p> -->
              <div class="enterTxt2"  v-html="responsibility">
              </div>
            </div>
            <div  class="enterInfo">
               <span @click="down" v-if="download">附件下载</span>
            </div>
            <!-- <div class="enterInfo">
              <div class="infoItem">
                <img style="width:1.77rem;height:1.77rem" src="../assets/img/tel.png" alt="">
                <span>023-68616888</span>
              </div>
              <div class="infoItem">
                <img style="width:1.77rem;height:1.77rem" src="../assets/img/msg.png" alt="">
                <span>glhb12345@126.com</span>
              </div>
              <div class="infoItem">
                <img style="width:1.77rem;height:1.77rem" src="../assets/img/pos.png" alt="">
                <span>重庆市渝中区虎踞路88号14-6</span>
              </div>
            </div> -->
          </div>
        </div>
        <div class="content"  v-if="active==2">
          <div class="title">
            <div class="title1">联系我们</div>
            <div class="title2">
              <span class="textFamily">Contact</span>
              <span class="textFamily">us</span>
            </div>
          </div>
          <div class="usBox">
            <div>
              <img src="../assets/img/map.png" alt="">
            </div>
          </div>
          <div class="enterInfo">
              <div class="infoItem">
                <img style="width:1.77rem;height:1.77rem" src="../assets/img/tel.png" alt="">
                <span class="span2">023-68616888</span>
              </div>
              <div class="infoItem">
                <img style="width:1.77rem;height:1.77rem" src="../assets/img/msg.png" alt="">
                <span class="span2">glhb12345@126.com</span>
              </div>
              <div class="infoItem">
                <img style="width:1.77rem;height:1.77rem" src="../assets/img/pos.png" alt="">
                <span class="span2">重庆市渝中区虎踞路88号1-6</span>
              </div>
            </div>
        </div>
      </div>
      <Bottom />
    </div>
  </template>
  
  <script>
  import Top from "@/components/Top.vue";
  import Bottom from "@/components/Bottom.vue";
  import myIcon from '../assets/img/logo2.png'; 
  export default {
    name: 'join',
    components: {
      Top,
      Bottom
    },
    data() {
      return {
        download:false,
        active:1,
        active2:'',
        locations:'',
        point:{
          lng:'',
          lat:''
        },
        data:[],
        title:[],
        itemNum:'',
        job_title:'',
        responsibility:'',
      }
    },
    mounted() {
      // this.getMap()
      this.getData()
      // this.getPoint()
    },
    methods: {
        down(){
            location.href=this.locations
        },
      toUS(){
        this.active=2
        // this.getMap()
      },
      getPoint(){
        let that = this
        var geolocation = new BMap.Geolocation();
        geolocation.getCurrentPosition(function(r){
            if(this.getStatus() == BMAP_STATUS_SUCCESS){
                that.point.lng = r.point.lng
                that.point.lat = r.point.lat
                that.getMap()
            }else {
                console.log('获取定位失败');
            }       
        },{enableHighAccuracy: true})
      },
      getMap(){
        this.map = new BMap.Map("map");
        var point = new BMap.Point(106.504516, 29.553788);
        // var point = new BMap.Point(106.53063501, 29.54460611);
        this.map.centerAndZoom(point, 13); 
        this.map.enableScrollWheelZoom(true)
  
        const deviceSize = new BMap.Size(300,60);
        const deviceIcon = new BMap.Icon(myIcon, deviceSize, { //会以base64的方式传参iconCar
                imageSize: deviceSize,
                });
        const marker = new BMap.Marker(point, {icon: deviceIcon});
        // var marker = new BMap.Marker(point);  
        this.map.addOverlay(marker); 
      },
      getData(){
        this.startLoading()
        this.axios.post("/app/Notice/noticeList").then(res => {
          if (res.data.code === 1) {
            this.data = res.data.data;
            this.data.map((item,index)=>{
              this.locations=item.file
              console.log(this.locations)
              if(this.locations){
                this.download= this.locations
              }
            })
            this.job_title = this.data[0].job_title
            this.responsibility = this.data[0].content
           
            this.active2 = this.data[0].id
            this.data.forEach(ele => {
              this.title.push({id:ele.id,title:ele.job_title})
              // this.$set(this.title, ele2.id, true)
            });
            this.endLoading()
          } else {
            this.$message(res.data.msg);
          }
        });
      },
      toItem(i){
        this.active2 = i
        this.data.forEach(ele=>{
          if(ele.id==i){
            this.job_title = ele.title
            this.responsibility = ele.content
            // this.locations=ele.file
            // console.log(this.locations)
            // if(this.locations){
            //   this.download=true
            // }
          }
        })

      }
    }
  }
  </script>
  
  <style scoped>
     .join{
      padding: 4.167rem 0 6.25rem 0;
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      font-family: Microsoft YaHei;
      color: #ffffff
    }
    .joinBox{
      width: 100%;
      height: 100%;
      background-image: url('../assets/img/bg.jpg');
      background-size: 100% 100%;
      /* background-repeat: no-repeat; */
      position: relative;
    }
    .content{
      width: 67.7rem;
      height: 100%;
      margin: 0 auto;
      padding-top: 2.5rem;
      padding-bottom: 2rem;
      overflow-y: scroll;
      opacity: 1;
    }
    .content2{
      opacity: 0;
    }
    .nav{
      width: 7rem;
      height: 5.2rem;
      position: absolute;
      left: 6.35rem;
      top: 10.4rem;
      display: flex;
      align-items: center;
    }
    .navImg{
      width: 6.25rem;
      height: 3.23rem;
    }
    .navList{
      height: 5.2rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .navItem{
      width: 6.25rem;
      height: 2.08rem;
      border: 0.05rem solid #1E90DC;
      line-height: 2.08rem;
      text-align: center;
      color: #1E90DC;
      cursor: pointer;
    }
    .active{
      background: #1E90DC;
      color: #ffffff!important
    }
    .title{
      text-align:center;
      margin-bottom: 2rem
    }
    .title1{
      font-size: 2.6rem;
      color: #FFFFFF;
      margin-bottom: 1rem
    }
    .title2 span{
      font-size: 3.13rem;
      font-family: Arial;
      font-weight: bold;
      color: #ffffff;
      /* margin-right: 2rem */
    }
    .box1{
      width: 67.7rem;
      height: 31.5rem;
      position: relative;
    }
    .enterBox{
      width: 67.7rem;
      /* height: 31.5rem; */
      background: rgba(30, 144, 220, 0.5);
      border: 0.104rem solid #1E90DC;
      /* padding-bottom: 2rem; */
    }
    .enterTitle{
      width: 100%;
      min-height: 4.06rem;
      line-height: 2.5rem;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      background-color: #1E90DC;
      padding: 0 2rem;
    }
    .enterTitle span{
      margin-right: 2.08rem;
      color: #DEEEFF;
      font-size: 0.83rem;
      cursor: pointer;
      margin-bottom: 0.5rem;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 10rem;
      white-space: nowrap;
    }
    .active2{
      font-size:1.1rem!important;
      color: #ffffff!important;
      border: 0.1rem solid #ffffff;
      border-radius: 0.5rem;
      padding: 0 0.5rem;
    }
    .enterContent{
      padding: 1.88rem 1.9rem;
    }
    .enterTxt1{
      font-size: 1.04em;
      font-weight: bold;
      margin-bottom: 1rem
    }
    .enterTxt2{
      font-size:0.83rem;
      line-height:2rem
    }
    .enterInfo{
      display: flex;
      margin-bottom: 2rem;
      cursor: pointer;
      /* justify-content:center; */
    }
    .enterInfo span{
      font-size: 1.04rem;
      margin-left: 2rem
    }
    .infoItem{
      margin: 0 2.1rem;
      display: flex;
      align-items: center
    }
    .span2{
      color: #1E90DC
    }
    .usBox{
      margin-bottom: 2rem;
      text-align: center;
    }
    .usBox div{
      display: inline-block;
      background: rgba(30, 144, 220, 0.5);
      border: 0.104rem solid #1E90DC;
      
      padding: 1rem 1rem;
    }
    .usBox2{
      opacity: 1;
    }
    #map{
      width:62.5rem;
      height:21.87rem;
    }
  </style>
  
  